<template>
    <div class="h-100">
        <v-layout fill-height align-center flex-row class="h-100 bg-transparent-blue">
            <v-card width="400" elevation="2" class="mx-auto px-8 pt-8 pb-4">
                <div class="text-center mb-5">
                    <img src="@/assets/images/Logo.png" width="150" />
                </div>
                <div>
                    <h2 class="text-center font-weight-bold mb-4">Please enter your code.</h2>
                    <v-form @submit.prevent="signIn">
                       <v-otp-input
                            ref="otpInput"
                            input-classes="otp-input"
                            separator=""
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-complete="handleOnComplete"
                            class="justify-center"
                            v-model="otp"
                        />
                        <div class="text-center mt-8">
                            <v-btn
                                class="btn btn-gradient-blue round h-100"
                                type="submit"
                                :disabled="busy"
                                :loading="busy"
                            >
                                Login
                            </v-btn>
                            <br>
                        </div>
                    </v-form>
                    <div class="text-center pt-3">
                        <a href="#" @click="resend" class="text-center mt-5">Resend OTP</a>
                    </div>
                    <div class="text-center pt-7">
                        <p class="ma-0">Need help? <a href="/contact">Contact support.</a></p>
                    </div>
                </div>
            </v-card>
        </v-layout>
        <div class="inquire text-center">
            <p class="mb-2 text-white">Do want to inquire about products and services of Koniko?</p>
            <p class="mb-2 text-white" >Feel free to contact us.
                <a class="pr-2 text-light-blue" href="mailto:konikosystems.inc@gmail.com">
                    <span>
                        <img src="@/assets/icon/Email.svg" width="16">
                    </span>
                    konikosystems.inc@gmail.com
                </a>
                <a class="pr-2 text-light-blue" href="tel:09177907949">
                    <span>
                        <img src="@/assets/icon/Call.svg" width="16">
                    </span>
                    09177907949
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            busy: false,
            show1: false,
            otp: '',
            user: '',
        }
    },
    methods: {
        handleOnComplete(value) {
            this.otp = value
        },
        signIn() {
            this.busy = true;
            this.$api
                .post('auth/verify-otp', {
                    otp: this.otp,
                    id: this.user.id
                })
                .then(({ data }) => {
                    if(data) {
                        this.$store.commit('auth/setToken', data.token)
                        this.busy = false;
                        location.href = '/dashboard/home'
                    }
                })
                .catch(err => {
                    this.busy = false;
                    this.$toast.open({
                        message:  err.response.data.msg,
                        type: 'error',
                        position: 'bottom'
                    })

                if (err.response.status === 422) {
                    this.form.$setErrors(err.response.data.errors)
                }
            })
        },
        resend()
        {
            this.busy = true;
            this.$api
                .post('auth/resend-otp', {
                    email: this.user.email
                })
                .then(({ data }) => {
                    this.busy = false;
                    this.$toast.open({
                        message:  'OTP Sent !',
                        type: 'success',
                        position: 'bottom'
                    })
                })
                .catch(err => {
                    this.busy = false;

                    this.$toast.open({
                        message:  err.response.data.msg,
                        type: 'error',
                        position: 'bottom'
                    })

                if (err.response.status === 422) {
                    this.form.$setErrors(err.response.data.errors)
                }
            })
        }
    },
    computed: {
        ...mapState({
            current_user: state => state.auth.user,
        }),
    },
    mounted:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        // console.log(this.current_user, 'here',JSON.parse(localStorage.getItem('user')));  
    },
}
</script>